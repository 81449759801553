<template>
    <div class="collapse show companyDetailsForm">
        <el-form
            ref="profileFormRef"
            :model="profileDetails"
            :rules="profileDetailsRules"
            label-width="300px">

            <div class="user-info-form mb-10" :class="{ 'has-no-access': !hasAccessToEditInfo }">
                <div class="w-xl-75">
                    <el-form-item :label="$t('Picture')" class="fullName" >
                        <FileUploadWithCropper
                            :imageUrl="profileDetails.icon_path === Constants.USER_TMP_LOGO ? null : profileDetails.icon_path"
                            :loading="fileUploadLoading"
                            :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
                            :acceptedSize="9216"
                            :blank-image-type="BlankImageTypesEnum.AVATAR"
                            :popup-title="$t('Crop Image')"
                            :popup-description="$t('Confirm your image upload by properly adjusting your Profile Picture')"
                            :disabled="!hasAccessToEditInfo"
                            @getImages="handleUpload"
                            @getErrors="errors => {fileUploadErrors = errors}"
                        />
                        <div class="el-form-item__error">{{ fileUploadErrors.join(", ") }}</div>
                    </el-form-item>
                    <el-form-item :label="$t('Full Name')" prop="first_name" class="fullName">
                        <el-input
                            v-model="profileDetails.first_name"
                            :disabled="!hasAccessToEditInfo"
                            :placeholder="$t('Input your First Name')" />
                        <el-input
                            v-model="profileDetails.last_name"
                            :disabled="!hasAccessToEditInfo"
                            :placeholder="$t('Input your Lat Name')" />
                    </el-form-item>
                    <el-form-item :label="$t('Job Title')" prop="job_title">
                        <el-input
                            :placeholder="$t('Please Input')"
                            :disabled="!hasAccessToEditInfo"
                            v-model="profileDetails.job_title"
                        ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Product Area')" prop="product_areas">
                        <SelectBoxWithModal
                            v-model="profileDetails.product_areas"
                            self-value
                            multiple
                            :is-disabled="!hasAccessToEditInfo"
                            :is-mandatory-select="false"
                            :options="userData.visible_product_areas"
                            :all-selected-text="$t('All Selected')"
                            :custom-selection-text="`${profileDetails.product_areas.length} ${$t('Product Areas')}`"
                            :placeholder="$t('Select Product Areas')"
                            :modal-title="$t('Select Product Areas')"
                            :modal-subtitle="$t('Select Product Areas')"
                            :modal-placeholder="$t('Search Product Areas')"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('Country of Residence')" prop="country">
                        <SelectBoxWithModal
                            v-model="profileDetails.country"
                            self-value
                            :is-disabled="!hasAccessToEditInfo"
                            :is-mandatory-select="false"
                            :options="countries"
                            :placeholder="$t('Select Country')"
                            :modal-title="$t('Select Country')"
                            :modal-subtitle="$t('Select Country')"
                            :modal-placeholder="$t('Search Country')"
                        />
                    </el-form-item>
                    <!--      <el-form-item class="mt-15" prop="role" :label="$t('Preferred Language')">-->
                    <!--        <el-select-->
                    <!--          v-model="profileDetails.language"-->
                    <!--          class="preferred-language"-->
                    <!--          popper-class="select-language-dropdown">-->
                    <!--          <template #prefix>-->
                    <!--            <img :src="profileDetailsLanguage.icon_path" width="20" height="20" alt="">-->
                    <!--            <span>{{ profileDetailsLanguage.title }}</span>-->
                    <!--          </template>-->
                    <!--          <el-option-->
                    <!--              v-for="(item, value) in supportedLanguages"-->
                    <!--              :key="value"-->
                    <!--              :value="value"-->
                    <!--          >-->
                    <!--            <div class="select-language__item">-->
                    <!--              <div class="select-language__item&#45;&#45;icon">-->
                    <!--                <img :src="item.icon_path" alt="">-->
                    <!--              </div>-->
                    <!--              <p>{{ item.title }}</p>-->
                    <!--              <span>{{ item.subTitle }}</span>-->
                    <!--            </div>-->
                    <!--          </el-option>-->
                    <!--        </el-select>-->
                    <!--      </el-form-item>-->
                </div>
            </div>

            <div class="user-info-form" :class="{ 'has-no-access': !hasAccessToEditPermissions }">
                <hr />

                <div class="permission-group--title mb-9 mt-10">
                    {{ $t('Permissions') }}
                </div>

                <template v-if="userData.role === UserRoleEnum.USER">
                    <div class="w-xl-75">
                        <el-form-item :label="$t('Permission Group')">
                            <el-select
                                filterable
                                :placeholder="$t('No Permission Group')"
                                :disabled="!hasAccessToEditPermissions"
                                v-model="permissionsFormGroupId"
                                @change="handleChangePermissionGroup"
                            >
                                <el-option
                                    v-for="(item,index) in permissionsGroups"
                                    :key="index"
                                    :value="item.id"
                                    :label="item.name"
                                >
                                    {{ item.name }}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <hr />
                </template>

                <div class="w-xl-75">
                    <div class="el-form-item mt-8">
                        <div class="el-form-item__label" style="width: 300px;">
                            {{ $t('Functions') }}
                            <el-tooltip
                                effect="dark"
                                placement="bottom-start"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                                <template #content>
                                    <div class="permission-tooltip">
                                        {{ $t('If all functions are disabled, user will be in a view-only mode.') }}
                                    </div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="el-form-item__content">
                            <div class="permission-group--input">
                                <div class="group-form-switch mb-4">
                                    <div class="group-form-switch-label switch-bold">
                                        {{ $t('All') }}
                                    </div>
                                    <el-switch v-model="allFunctions" :disabled="!hasAccessToEditPermissions" @change="handleChangedAll" />
                                </div>
                                <div class="group-form-divider"></div>

                                <div
                                    class="group-form-switch mb-6 mt-4"
                                    v-for="(item, key) in permissionsListForShow"
                                >
                                    <div class="group-form-switch-label">
                                        {{ $t(item) }}
                                    </div>

                                    <label class="switch">
                                        <input
                                            v-model="permissionsForm.functional_permissions"
                                            @change="handleChangePermissions"
                                            type="checkbox"
                                            :disabled="!hasAccessToEditPermissions"
                                            :name="key"
                                            :value="key"
                                        >
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-xl-75" v-if="userData.role === UserRoleEnum.USER">
                    <div class="el-form-item mt-8">
                        <div class="el-form-item__label mt-4" style="width: 300px;">
                            {{ $t('Personal Information') }}
                        </div>
                        <div class="el-form-item__content">
                            <div class="permission-group--input">
                                <div
                                    class="group-form-switch mb-6 mt-4"
                                    v-for="(item, key) in usersPersonalInformationPermissions"
                                >
                                    <div class="group-form-switch-label">
                                        {{ $t(item) }}
                                    </div>

                                    <label class="switch">
                                        <input
                                            v-model="permissionsForm.functional_permissions"
                                            @change="handleChangePermissions"
                                            type="checkbox"
                                            :disabled="!hasAccessToEditPermissions"
                                            :name="key"
                                            :value="key"
                                        >
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-xl-75" v-if="userData.role === UserRoleEnum.USER">
                    <div class="el-form-item mt-8">
                        <div class="el-form-item__label" style="width: 300px;">
                            {{ $t('Access') }}
                            <el-tooltip
                                effect="dark"
                                placement="bottom-start"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                                <template #content>
                                    <div class="permission-tooltip">
                                        {{ $t('Give access to specific product areas, countries or banks by selecting them in the boxes on the right. Selecting all will give access to future additions also.') }}
                                    </div>
                                </template>
                            </el-tooltip>
                        </div>
                        <div class="el-form-item__content">
                            <div class="group-form-input mb-4">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Banks") }}</h4>
                                    <button
                                        v-if="businessBanksList.length && permissionsFormUserRole.banks.length !== businessBanksList.length"
                                        @click="resetToDefault('banks', businessBanksListIds)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <CustomersBanksSelectBox
                                    v-model="permissionsFormUserRole.banks"
                                    multiple
                                    :options="businessBanksList"
                                    :placeholder="$t('Select Banks')"
                                    :modal-title="$t('Add Banks')"
                                    :modal-subtitle="$t('Select the banks to include in Permission Group.')"
                                    :is-mandatory-select="false"
                                    :is-for-bank-platform="true"
                                    :show-logo="false"
                                    :additional-columns="[{
                                        prop: 'countries',
                                        label: $t('Country'),
                                        minWidth: '200px',
                                        isMultiple: true,
                                    }]"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                            <div class="group-form-input mb-4">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Countries") }}</h4>
                                    <button
                                        v-if="businessCountriesList.length && permissionsFormUserRole.countries.length !== businessCountriesList.length"
                                        @click="resetToDefault('countries', businessCountriesList)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <SelectBoxWithModal
                                    v-model="permissionsFormUserRole.countries"
                                    self-value
                                    multiple
                                    :options="businessCountriesList"
                                    :all-selected-text="$t('All Selected')"
                                    :is-mandatory-select="false"
                                    :custom-selection-text="`${permissionsFormUserRole.countries.length} ${$t('Countries')}`"
                                    :placeholder="$t('Select Countries')"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                            <div class="group-form-input">
                                <div class="group-form-input-label">
                                    <h4>{{ $t("Product Areas") }}</h4>
                                    <button
                                        v-if="businessProductAreasList.length && permissionsFormUserRole.product_areas.length !== businessProductAreasList.length"
                                        @click="resetToDefault('product_areas', businessProductAreasList)"
                                    >{{ $t('Reset to default') }}</button>
                                </div>
                                <SelectBoxWithModal
                                    v-model="permissionsFormUserRole.product_areas"
                                    self-value
                                    multiple
                                    :options="businessProductAreasList"
                                    :all-selected-text="$t('All Selected')"
                                    :is-mandatory-select="false"
                                    :custom-selection-text="`${permissionsFormUserRole.product_areas.length} ${$t('Product Areas')}`"
                                    :placeholder="$t('Select Product Areas')"
                                    :is-disabled="!hasAccessToEditPermissions"
                                    @onTouch="handleChangePermissions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
        <hr />
        <div class="d-flex mt-10 justify-content-end align-items-center">
            <el-button @click="resetForm">{{ $t("Discard") }}</el-button>
            <button
                :data-kt-indicator="buttonIndicator"
                :disabled="submitButtonState"
                class="main-btn btn"
                @click="onSubmit"
            >
                <span class="indicator-label"> {{ $t("Save Changes") }}</span>

                <span class="indicator-progress">
                    {{ $t("pleaseWait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
import { Constants, SUPPORTED_LANGUAGES } from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BlankImageTypesEnum } from "@/store/enums/BlankImageTypesEnum";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal.vue";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";
import CustomersBanksSelectBox from "@/buying-teams/shared-components/settings/CustomersBanksSelectBox.vue";
import { BusinessTeamUserModel } from "@/store/models/business/BusinessTeamUserModel";
import NotificationService from "@/buying-teams/services/NotificationService";
import { filterAllowedBanks } from "@/store/models/business/permissions/BusinessPermissionGroup";

const usersFunctionsPermissions = {
    [BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION]: 'Create feedback session',
    [BusinessUserPermissionTypeEnum.REPLY_FEEDBACK_SESSION]: 'Reply to feedback session',
    [BusinessUserPermissionTypeEnum.PU_INVITE_USER]: 'Invite users',
    [BusinessUserPermissionTypeEnum.SHARE_REQUESTS_WITH_BANK]: 'Share requests with bank',
    [BusinessUserPermissionTypeEnum.SHARE_IDEAS_WITH_BANK]: 'Share ideas with bank',
    [BusinessUserPermissionTypeEnum.COMMENT_ON_REQUESTS]: 'Comment on requests',
    [BusinessUserPermissionTypeEnum.COMMENT_ON_IDEAS]: 'Comment on ideas',
    [BusinessUserPermissionTypeEnum.OPEN_CLOSE_REQUESTS_AND_IDEAS]: 'Open/close requests and ideas',
};

const usersPersonalInformationPermissions = {
    [BusinessUserPermissionTypeEnum.SHARE_FULL_NAME_WITH_BANK]: 'Allow users to share full name with bank',
    [BusinessUserPermissionTypeEnum.SHARE_JOB_TITLE_WITH_BANK]: 'Allow users to share job title with bank',
    [BusinessUserPermissionTypeEnum.SHARE_PROFILE_PICTURE_WITH_BANK]: 'Allow users to share profile picture with bank',
    [BusinessUserPermissionTypeEnum.SHARE_COUNTRY_WITH_BANK]: 'Allow users to share country of residence with bank',
};

const usersPermissions = { ...usersFunctionsPermissions, ...usersPersonalInformationPermissions };

const adminsPermissions = {
    [BusinessUserPermissionTypeEnum.PA_INVITE_USER]: 'Invite user',
    [BusinessUserPermissionTypeEnum.EDIT_USER]: 'Edit user',
    [BusinessUserPermissionTypeEnum.PROMOTE_DEMOTE_USER]: 'Promote/demote user',
    [BusinessUserPermissionTypeEnum.DELETE_USER]: 'Delete user',
    [BusinessUserPermissionTypeEnum.EDIT_BUSINESS]: 'Edit business',
    [BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS]: 'Change user permissions and permission groups',
    [BusinessUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS]: 'Change admin permissions',
    [BusinessUserPermissionTypeEnum.INVITE_ADMIN]: 'Invite admin',
    [BusinessUserPermissionTypeEnum.EDIT_ADMIN]: 'Edit admin',
    [BusinessUserPermissionTypeEnum.DELETE_ADMIN]: 'Delete admin',
};

export default {
    name: "BusinessSingleUserEditForm",

    emits: ["handle-discard", "onProfileUpdated"],

    components: {
        CustomersBanksSelectBox,
        SelectBoxWithModal,
        FileUploadWithCropper
    },

    props: {
        userData: {
            type: [Object, BusinessTeamUserModel]
        },
        buttonIndicator: String,
        permissionsGroups: Array,
    },

    data() {
        return {
            BlankImageTypesEnum,
            UserRoleEnum,
            usersPersonalInformationPermissions,
            supportedLanguages: SUPPORTED_LANGUAGES,
            Constants,
            fileUploadErrors: [],
            fileUploadLoading: false,
            profileDetails: {
                icon_path: "",
                first_name: "",
                last_name: "",
                country: "",
                job_title: "",
                business_banks: [],
                product_areas: [],
                // language: '',
            },
            profileDetailsRules: {
                first_name: [
                    { required: true, message: this.$t("Please input First Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                last_name: [
                    { required: true, message: this.$t("Please input Last Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                country: [
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
                job_title: [
                    { required: true, message: this.$t("Please input Job title"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ],
            },
            allFunctions: false,
            permissionsForm: {
                functional_permissions: [],
            },
            permissionsFormGroupId: null,
            permissionsFormUserRole: {
                banks: [],
                countries: [],
                product_areas: [],
            },
            buttonIndicator: "off",
        };
    },
    computed: {
        businessProductAreasList() {
            return store.getters.businessProductAreas
        },
        businessCountriesList() {
            return store.getters.businessCountries;
        },
        countries() {
            return store.getters.allCountries;
        },
        submitButtonState() {
            return this.buttonIndicator === "on" || this.fileUploadLoading;
        },
        profileDetailsLanguage() {
            return {
                icon_path: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].icon_path : "/media/buying/icons/languages/globe-earth.svg",
                title: this.profileDetails.language ? this.supportedLanguages[this.profileDetails.language].title : this.$t("Select")
            };
        },
        currentUser() {
            return store.getters.currentUser
        },
        permissionsList() {
            return this.userData.role === UserRoleEnum.USER ? usersPermissions : adminsPermissions;
        },
        permissionsListForShow() {
            return this.userData.role === UserRoleEnum.USER ? usersFunctionsPermissions : adminsPermissions;
        },
        businessBanksList() {
            return store.getters.businessBanks;
        },
        businessBanksListIds() {
            return this.businessBanksList.map(c => c.id);
        },
        hasAccessToEditInfo() {
            return (this.userData.role === UserRoleEnum.USER && this.currentUser.can(BusinessUserPermissionTypeEnum.EDIT_USER) ||
                (this.userData.role === UserRoleEnum.ADMIN && this.currentUser.can(BusinessUserPermissionTypeEnum.EDIT_ADMIN)))
        },
        hasAccessToEditPermissions() {
            return (this.userData.role === UserRoleEnum.USER && this.currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS) ||
                (this.userData.role === UserRoleEnum.ADMIN && this.currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS)))
        }
    },

    async mounted() {
        this.fillForm({ ...this.userData });
    },

    methods: {
        handleUpload(files) {
            if (!files.length) {
                this.profileDetails.icon_path = null;
                return;
            }

            this.fileUploadLoading = true;
            store.dispatch("imageUpload", files).then(responses => {
                this.profileDetails.icon_path = responses.key;
                this.fileUploadLoading = false;
            }).catch(() => {
                this.fileUploadLoading = false;
            });
        },

        fillForm(userData) {
            this.profileDetails = {
                icon_path: userData.icon_path,
                first_name: userData.first_name,
                last_name: userData.last_name,
                country: userData.country || '',
                job_title: userData.job_title,
                product_areas: userData.product_areas,
                // language: userData.language,
                business_banks: userData.business_banks.map(item => {
                    return {
                        countries: item.countries,
                        bank_name: item.name
                    };
                })
            };
            this.fillPermissionsForm(userData);
        },

        resetForm() {
            this.fillForm({ ...this.userData });
            this.$emit("handle-discard");
        },

        fillPermissionsForm(userData) {
            if (userData.role === UserRoleEnum.ADMIN) {
                this.permissionsForm.functional_permissions = userData.permissions.functionalPermissions.filter(p => !p.includes('pu') && !p.includes('pi'));
            } else if (userData.role === UserRoleEnum.USER) {
                if (userData.permissions.isGroup) {
                    this.permissionsFormGroupId = userData.permissions.id;
                }
                this.permissionsForm.functional_permissions = userData.permissions.functionalPermissions;
                this.permissionsFormUserRole.countries = userData.permissions.allowedCountries;
                this.permissionsFormUserRole.product_areas = userData.permissions.allowedProductAreas;
                this.permissionsFormUserRole.banks = filterAllowedBanks(userData.permissions.blockedBanks, this.businessBanksListIds);
            }
        },

        handleChangePermissionGroup() {
            const selectedGroup = this.permissionsGroups.find(p => p.id === this.permissionsFormGroupId);
            if (selectedGroup) {
                this.fillPermissionsForm({
                    ...this.userData,
                    permissions: selectedGroup
                })
            }
        },

        handleChangePermissions() {
            this.permissionsFormGroupId = null;
        },

        handleChangedAll(value) {
            this.permissionsForm.functional_permissions = value ? Object.keys(this.permissionsList) : [];
            this.handleChangePermissions();
        },

        async onSubmit() {
            let payload = {
                id: this.$route.params.id,
            }

            if (this.hasAccessToEditPermissions) {
                if (this.permissionsFormGroupId) {
                    payload.permissions = {};
                    payload.permissions.group_id = this.permissionsFormGroupId
                } else {
                    payload.permissions = {
                        ...this.permissionsForm,
                        ...this.permissionsFormUserRole,
                    }
                    payload.permissions.banks = filterAllowedBanks(this.permissionsFormUserRole.banks, this.businessBanksListIds);
                }

                if (this.userData.role === UserRoleEnum.ADMIN) {
                    payload.permissions = {
                        functional_permissions: this.permissionsForm.functional_permissions
                    }
                }
            }

            if (this.hasAccessToEditInfo) {
                payload = { ...payload, user_data: { ...this.profileDetails } };
            }

            this.$refs.profileFormRef.validate(async valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    await store.dispatch("updateUserDetails", { ...payload, is_edit_form: true })
                        .then(res => {
                            if (res) {
                                NotificationService.swalCustom({
                                    title: "Success",
                                    icon: "success"
                                });
                                this.$emit('onProfileUpdated');
                            }
                        }).finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            })
        },
        resetToDefault(key, allList) {
            this.permissionsFormUserRole[key] = [...allList];
            this.permissionsFormGroupId = null;
        }
    },
    watch: {
        'permissionsForm.functional_permissions': {
            deep: true,
            handler(newVal) {
                this.allFunctions = newVal.length === Object.keys(this.permissionsList).length;
            }
        },
        'permissionsFormUserRole.product_areas': {
            deep: true,
            handler(newVal) {
                if (this.userData.role === UserRoleEnum.USER) {
                    if (newVal) {
                        this.userData.setProductAreas(newVal);
                        this.profileDetails.product_areas = this.profileDetails.product_areas.filter(pa => newVal.includes(pa));
                    }

                }
            }
        }
    }
};
</script>
<style lang="scss">
.companyDetailsForm {
    .fullName {
        .el-form-item__content {
            display: flex;
            gap: 20px;
        }
    }

    .permission-group {
        &--title {
            color: #242424;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        }
    }

    .group-form-input-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        h4 {
            color: #595959;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            margin-bottom: 0;
        }
        button {
            color: #435BF4;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            background: transparent;
            border: none;
            padding: 0;
            &:hover {
                opacity: .8;
            }
        }
    }

    .group-form-divider {
        width: 100%;
        height: 1px;
        background: #EBEEF5;
    }

    .group-form-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .group-form-switch-label {
            color: #242424;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            &.switch-bold {
                font-weight: 700;
            }
        }
    }

    .user-info-form {
        &.has-no-access {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 6;
            }
            * {
                user-select: none;
            }
            .permission-group--title, .el-form-item__label, .group-form-switch-label, .group-form-input-label, .image-cropper {
                opacity: .6;
            }
        }
    }

}
.permission-tooltip {
    max-width: 292px;
}
</style>
