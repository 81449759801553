<template>
    <div class="mx-0 h-100" v-if="loading">
        <CustomLoader />
    </div>
    <div class="single-user" :class="{'is_blocked': profileData.is_blocked}" v-else>
        <div class="single-user__info card mb-5 p-0 mb-xxl-8">
            <div class="card-body">
                <div class="d-flex flex-wrap flex-sm-nowrap settings-section">
                    <div class="me-7 mb-5">
                        <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            <img :src="profileData.icon_path" alt="image" />
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-items-center mb-2">
                                    <p class="single-user__name">
                                        {{ profileData.getFullName() }}
                                    </p>
                                    <strong class="single-user__status" v-if="profileData.is_blocked">
                                        {{ $t("Blocked") }}
                                    </strong>
                                </div>

                                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    <p class="d-flex align-items-center text-gray-400 me-5 mb-0">
                                        {{ profileData.job_title }}
                                    </p>
                                    <p class="border-start border-gray-300 me-6 m-0"></p>
                                    <p class="d-flex align-items-center text-gray-400 me-5 mb-0">
                                        {{ $t(`role.${ profileData.role }`) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap flex-stack">
                            <div class="d-flex flex-column flex-grow-1 pe-8">
                                <div class="d-flex flex-wrap user-counter"
                                     v-if="profileData.feedback && profileData.business_banks">
                                    <div
                                        class="border border-gray-300 rounded min-w-175px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="fw-bold fs-6 text-gray-400">{{ $t("Total Score") }}</div>
                                            <div
                                                class="fs-1 fw-boldest"
                                                data-kt-countup="true"
                                                data-kt-countup-value="4500"
                                                data-kt-countup-prefix="$">
                                                {{ numberDecimalsFormat(profileData.stats.score) }}
                                            </div>
                                        </div>
                                    </div>
                                    <p class="border-start  border-gray-300 me-6"></p>
                                    <div class="border border-gray-300 rounded min-w-175px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="fw-bold fs-6 text-gray-400">{{ $t("Questions Replied") }}</div>
                                            <div
                                                class="fs-1 fw-boldest"
                                                data-kt-countup="true"
                                                data-kt-countup-value="75">{{ profileData.feedback.answers }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="border border-gray-300 rounded min-w-175px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="fw-bold fs-6 text-gray-400">{{ $t("Requests & Ideas") }}</div>
                                            <div
                                                class="fs-1 fw-boldest"
                                                data-kt-countup="true"
                                                data-kt-countup-value="60"
                                                data-kt-countup-prefix="%"
                                            >
                                                {{ profileData.feedback.ideas + profileData.feedback.requests }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-5 g-xxl-8 mx-0">
            <div class="card mb-5 mb-xl-10 py-2" id="kt_profile_details_view" ref="editForm">
                <div class="card-header cursor-pointer px-0">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("Profile Details") }}</h3>
                    </div>
                    <div v-if="!editProfile && hasAccessToEdit">
                        <p @click="editProfile = true" class="text-primary edit my-3 d-block mb-5">
                            <img height="12" class="me-3" src="/media/buying/icons/edit.svg" alt="">
                            {{ $t("Edit profile") }}
                        </p>
                    </div>
                </div>
                <ProfileDetails
                    v-if="!editProfile"
                    :profileDetails="profileData"
                    :businessData="business"
                />
                <BusinessSingleUserEditForm
                    v-else
                    :userData="profileData"
                    :permissions-groups="permissionsGroups"
                    @handle-discard="editProfile = false"
                    @onProfileUpdated="onProfileUpdated"
                />
            </div>

            <div v-if="!editProfile" class="card pt-3 mt-0 p-0">
                <div class="card-header cursor-pointer px-10">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("Active Banks") }}</h3>
                        <span class="text-with-bg ms-3">
                            {{ profileData.business_banks ? profileData.business_banks.length : 0 }}
                        </span>
                    </div>

                    <AddBanks
                        v-if="isAddBanksVisible"
                        :isOwn="false"
                        :isAddBanksVisible="isAddBanksVisible && user.isAdmin"
                        :userInfo="profileData"
                        :isEditBank="isEditBank"
                        :dataForEdit="dataForEdit"
                        @handle-close="isAddBanksVisible = false"
                        @handle-save="saveBanks"
                    />

                    <ConfirmationModal
                        title="Deleting Bank"
                        description="Are you sure, you want to delete this bank"
                        btnName="Delete"
                        :popupVisible="visibleDeleteModal"
                        @handleClose="visibleDeleteModal = false"
                        @handleConfirm="deleteBank"
                    />
                    <button
                        v-if="user.isAdmin"
                        class="engage-filter-toggle fw-bold fs-4 align-self-center btn btn-light-primary py-1"
                        @click="openAddBanksModal"
                    >
                        <inline-svg width="15" class="me-5" src="/media/buying/icons/plus.svg" />
                        {{ $t("Add Bank") }}
                    </button>
                </div>
                <BusinessBanksTable
                    :showAction="showBankTableAction"
                    v-if="profileData.business_banks"
                    :banksData="profileData.business_banks"
                    @handle-edit="editBank"
                    @handle-delete="visibleDeleteModal = true; bankData = $event"
                />
            </div>

            <div v-if="!editProfile" class="card mb-5 mb-xl-10 p-0" id="kt_profile_details_view1">
                <div class="card-header cursor-pointer px-10 py-5 align-items-center">
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("Active Product Areas") }}</h3>
                    </div>
                    <button
                        class="engage-filter-toggle fw-bold fs-4 align-self-center btn btn-light-primary py-1"
                        v-if="!profileData.product_area_data || !Object.keys(profileData.product_area_data).length"
                        @click="handleAddProductArea"
                    >
                        <inline-svg width="15" class="me-5" src="/media/buying/icons/plus.svg" />
                        {{ $t("Add Product Areas") }}
                    </button>
                    <!--          <el-select class="selectProductArea" v-model="value" :placeholder="$t('Select Product Area')">-->
                    <!--            <el-option-->
                    <!--              v-for="item in options"-->
                    <!--              :key="item.value"-->
                    <!--              :label="item.label"-->
                    <!--              :value="item.value"-->
                    <!--              :disabled="item.disabled"-->
                    <!--            />-->
                    <!--          </el-select>-->
                </div>
                <ProductAreas
                    v-if="profileData && profileData.product_area_data"
                    :profileInfo="profileData"
                    :page="APP_PAGES.BUSINESS.SINGLE_USER"
                />
            </div>

            <!--      <div class="card pt-3 p-0">-->
            <!--        <div class="card-header cursor-pointer px-10">-->
            <!--          <div class="card-title m-0">-->
            <!--            <h3 class="fw-bolder m-0">{{ $t("Your Activity") }}-->
            <!--            </h3>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--        <div class="coming-soon-section">-->
            <!--          {{ $t('Coming Soon') }}-->
            <!--        </div>-->
            <!--        <div class="d-flex align-items-center justify-content-between px-10 mt-7">-->
            <!--          <p class="fs-4 fw-bold text-gray-600">“Questions replied monthly” by Kolt Inc</p>-->
            <!--          <p class="fs-4 fw-bold">10.021 questions replied</p>-->
            <!--        </div>-->
            <!--        <BarChart />-->
            <!--      </div>-->

        </div>
    </div>
</template>
<script>
import ProductAreas from "@/buying-teams/shared-components/settings/ProductAreas";
import ProfileDetails from "@/buying-teams/shared-components/settings/ProfileDetails";
import BusinessSingleUserEditForm from "@/buying-teams/shared-components/settings/BusinessSingleUserEditForm.vue";
import BanksList from "@/buying-teams/shared-components/settings/BanksList";
import BusinessBanksTable from "@/buying-teams/shared-components/settings/BusinessBanksTable";
import AdminsList from "@/buying-teams/shared-components/settings/AdminsList";
import BarChart from "@/buying-teams/shared-components/charts/BarChart";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import AddBanks from "@/buying-teams/shared-components/settings/AddBanks";
import ConfirmationModal from "../../../shared-components/modals/ConfirmationModal";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BusinessBankModel } from "@/store/models/BusinessBankModel";
import { BusinessFilterService } from "@/core/services/BusinessFilterService";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { diagLog, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import { APP_PAGES } from "@/core/config/constants";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";
import { BusinessTeamUserModel } from "@/store/models/business/BusinessTeamUserModel";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";

export default {
    name: "SingleUser",

    components: {
        CustomLoader,
        ProfileDetails,
        BanksList,
        AdminsList,
        BusinessBanksTable,
        ProductAreas,
        BarChart,
        BusinessSingleUserEditForm,
        AddBanks,
        ConfirmationModal
    },

    data() {
        return {
            loading: true,
            options: [],
            value: "",
            visibleDeleteModal: false,
            profileData: null,
            editProfile: false,
            isAddBanksVisible: false,
            isEditBank: false,
            dataForEdit: null,
            bankData: null,
            numberDecimalsFormat,
            APP_PAGES,
            BusinessUserPermissionTypeEnum,
            permissionsGroups: [],
        };
    },

    computed: {
        user() {
            return store.getters.currentUser;
        },
        business() {
            return store.getters.business;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        businessBanks() {
            return store.getters.businessBanks;
        },
        showBankTableAction() {
            return this.user.isAdmin || this.user.id === +this.$route.params.id;
        },
        hasAccessToEdit() {
            return this.user.isAdmin && (
                this.profileData.role === UserRoleEnum.USER && (this.user.can(BusinessUserPermissionTypeEnum.EDIT_USER) || this.user.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) ||
                this.profileData.role === UserRoleEnum.ADMIN && (this.user.can(BusinessUserPermissionTypeEnum.EDIT_ADMIN) || this.user.can(BusinessUserPermissionTypeEnum.CHANGE_ADMIN_PERMISSIONS))
            )
        }
    },
    async mounted() {
        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.userInfo.first_name},  <span>${this.$t("welcomeBack")}</span>` });
        if (+this.$route.params.id === this.userInfo.id) {
            redirectToRouteWithName("user-setting-overview");
        }
        await store.dispatch("fetchStaticData", StaticDataEnum.PRODUCT_AREAS);
        this.getTeamDetail();
    },

    methods: {
        async getTeamDetail() {
            this.loading = true;
            return Promise.all([
                store.dispatch("getTeamDetails", this.$route.params.id),
                store.dispatch("getTeamUserStats", +this.$route.params.id),
            ]).then( async (res) => {
                this.profileData = new BusinessTeamUserModel({ ...res[0], stats: res[1] });
                this.profileData.setProductAreas(store.getters.allProductAreas);
                if (this.profileData.role === UserRoleEnum.USER) {
                    this.permissionsGroups = await store.dispatch('getBusinessPermissionsGroups');
                }
                diagLog('profileData => ', this.profileData);
            }).finally(() => {
                this.loading = false;
            });
        },
        openAddBanksModal() {
            this.isAddBanksVisible = true;
            this.isEditBank = false;
        },
        editBank(data) {
            this.dataForEdit = data;
            this.isEditBank = true;
            this.isAddBanksVisible = true;
        },
        async deleteBank() {
            let data = [...this.profileData.business_banks];
            data.splice(data.indexOf(this.bankData), 1);
            let params = {
                business_banks: data.map(item => {
                    return {
                        bank_name: item.name,
                        countries: item.countries
                    };
                }),
                product_areas: this.user.product_areas
            };
            await store.dispatch("updateProfileDetails", params).then(res => {
                if (res) {
                    this.profileData.business_banks.splice(this.profileData.business_banks.indexOf(this.bankData), 1);
                }
            });
        },

        saveBanks(data) {
            let newBank = new BusinessBankModel(data);
            let existingUserBank = this.profileData.business_banks.find(u_bank => u_bank.id === newBank.id);
            let existingBusinessBanks = this.businessBanks.find(u_bank => u_bank.id === newBank.id);

            if (!existingBusinessBanks) {
                BusinessFilterService.addNewBankInFilters(newBank);
            }

            if (!existingUserBank) {
                this.profileData.business_banks.push(newBank);
            } else {
                this.profileData.business_banks.forEach(val => {
                    if (val.id === newBank.id) {
                        val.countries = newBank.countries;
                    }
                });
            }
        },

        handleAddProductArea() {
            this.editProfile = true;

            const element = this.$refs.editForm;
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" })
            }
        },

        onProfileUpdated() {
            this.getTeamDetail();
            this.editProfile = false;
        }
    }
};
</script>
<style lang="scss">
.single-user {
    &__name {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #000000;
        opacity: 0.87;
        margin-bottom: 0;
    }

    &__status {
        font-weight: 500;
        font-size: 10px;
        line-height: 158.02%;
        color: #E22D21;
        text-transform: uppercase;
        margin-left: 16px;
    }

    &__info {
        .card-body {
            margin-bottom: -1.25rem;
        }
    }

    &.is_blocked {
        .symbol img {
            opacity: .5;
        }
    }
}

.selectProductArea {
    .el-input__inner {
        background: #F6F6F6;
        border: 1px solid #435BF4;
        border-radius: 26px;
        height: 45px;
        padding: 0 20px;
    }
}

.symbol.symbol-lg-160px.symbol-fixed > img {
    object-fit: cover;
    object-position: top;
}
</style>
